import Title from "../componentes/Title";
import IconPage from "../componentes/IconPage";
import ItinerarioContainer from "../componentes/ItinerarioContainer";
import clave from '../img/panelistas.svg'
import map from '../img/map.svg'
import '../App.css'
import { Layout } from "../layout";
import IconosVolver from "../componentes/IconosVolver";
import Encabezado from "../componentes/Encabezado";
import QR from "../img/qr-sedecentral.svg";

const Wifi = ({ image, indice, height }) => {

    // const style = {
    //     button: {
    //         color: '#fff',
    //         padding: '1rem',
    //         background: '#3EC3FF',
    //         width: '100%',
    //         border: 'none',
    //         fontSize: '1.5rem'
    //     }
    // }
    // const style = {
    //     text: {
    //         fontweight:'900'

    //     },
        // text_n:{
        //     color:'#000000'
        // }
    // }
    return (
        <>

            <Layout textFooter={'federación argentina del trabajador de las universidades nacionales'}>
                <Encabezado/>
                <IconosVolver to={'/'} />
                <IconPage image={clave} indice={'Acceso al WIFI'} />


                <div className="mapIframe pt-4">
                    <img className='imgIframe' src={QR}/>
                    {/* <iframe className='imgIframe' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12545.55096256336!2d-64.52852639795945!3d-32.07008656165863!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9432986f9eb90f67%3A0x240ff30b163ee7be!2sHotel%20Evita!5e0!3m2!1ses!2sar!4v1680528004939!5m2!1ses!2sar" width="600" height="450" frameborder="0" style={{ border: 0, }} allowfullscreen="" aria-hidden="false" tabindex="0">
                    </iframe> */}
                </div>

                <div style={{ margin: 'auto', textAlign: 'center' }} className='mt-2 buttonUbicacion'>
                    <h1 className='poppins-bold mt-4 mb-4 is-size-4-desktop is-size-6-mobile'> Red:<p>Fatun_01</p></h1>
                    <h1  className='poppins-bold mt-4 mb-4 is-size-4-desktop is-size-6-mobile'> Clave: <p >Fatun1234</p></h1>
                    {/* <a href='https://maps.app.goo.gl/qc4sPdhnpFk3geXP8?g_st=iwb'>
                        <button style={style.button} className='poppins-bold is-uppercase mt-4 mb-4 is-size-4-desktop is-size-6-mobile'>¿Cómo llegar?</button>
                    </a> */}
                </div>
            </Layout>

        </>
    )
}


export default Wifi;
