import HoraCharla from './HoraCharla'
import DetalleCharla from './DetalleCharla'
import Disertante from './Disertante'
import clock from '../img/clock.svg'
import '../App.css'
import { Link } from 'react-router-dom'


const ItinerarioContainer = () => {
    return (
    <div className='mb-6'>
        <div className='mb-4 mt-6'>
            <div className="mt-4 poppins-bold gridPrograma">
            <img src={clock} style={{maxWidth: '1.8rem', margin:'auto'}}/>
                <p className='color is-size-4-desktop is-size-6-mobile'>Miércoles 4 de octubre</p>
             </div>
        </div>

        <div className='gridPrograma'>
            <HoraCharla hora={'09:00'}/>
            <div>
                <DetalleCharla tema={'Acreditación y desayuno.'} />
            </div>
        </div>


        <div className='gridPrograma'>
            <HoraCharla hora={'10:00'}/>
            <div>
                <DetalleCharla tema={'PRESENTACIÓN - Bienvenida e inicio del encuentro.'} />
                <Disertante disertante={'Cro. Walter Merkis'} cargo={'Secretario General de la FATUN'}/>
                <Disertante disertante={'Cra. Ayelén Mazzina'} cargo={'Ministra de Mujeres, Género y Diversidad de la Nación'}/>
                <Disertante disertante={'Cra. Alicia Luna'} cargo={'Secretaria de Igualdad de Género FATUN'}/>
                <Disertante disertante={'Cra. Ana Laura Ruggiero'} cargo={'Subsecretaria Igualdad de Género FATUN'}/>
            </div>

        </div>


        <div className='gridPrograma'>
            <HoraCharla hora={'11:00'}/>
            <div>
                <DetalleCharla tema={'1er. Panel: "Mujer y participación política"'} />
                <Disertante disertante={'Cra. Dora Barrancos'} cargo={'Investigadora del CONICET'}/>
                <Disertante disertante={'Cra. María Rosa Martínez'} cargo={'Diputada Nacional PBA'}/>
                <Disertante disertante={'Cra. Ana Laura Ruggiero'} cargo={'Subsecretaria Igualdad de Género FATUN'}/>
                <Disertante disertante={'Modera: Cra. Susana Ramos'} cargo={'Subsecretaria de Acción Gremial FATUN'}/>
            </div>
        </div>


        <div className='gridPrograma'>
            <HoraCharla hora={'12:30'}/>
            <div>
                <DetalleCharla tema={'2do. Panel: "La comunicación en clave feminista"'} />
                <Disertante disertante={'Cra. Dra. Cynthia Ottaviano'} cargo={'Dra. en Comunicación'}/>
                <Disertante disertante={'Modera: Cra. Ana Laura Ruggiero'} cargo={'Subsecretaria de Igualdad de Género FATUN'}/>
            </div>
        </div>


        <div className='gridPrograma'>
            <HoraCharla hora={'13:30'}/>
            <div>
                <DetalleCharla tema={'Almuerzo.'} />
            </div>
        </div>


        <div className='gridPrograma'>
            <HoraCharla hora={'15:00'}/>
            <div>
                <DetalleCharla tema={'3er. Panel: "Convenio 190, Recomendación 206 y protocolo de violencia laboral".'} />
                <Disertante disertante={'Cra. Viviana García'} cargo={'FEPROSA'}/>
                <Disertante disertante={'Cra. Mara Rivera'} cargo={'APSEE'}/>
                <Disertante disertante={'Cra. Monje Adriana'} cargo={'Secretaria Adjunta de UDOCBA'}/>
            </div>
        </div>


        <div className='gridPrograma'>
            <HoraCharla hora={'16:00'}/>
            <div>
                <DetalleCharla tema={'4to. Panel: "Mujeres y diversidades en lucha por la memoria, la verdad y la justicia"'} />
                <Disertante disertante={'Cra. María Julia Barsotelli'} cargo={'Secretaria de Derechos Humanos FATUN'}/>
                <Disertante disertante={'Cra. María Elena Patzer'} cargo={'Vicerrectora Instituto Universitario Madres de Plaza de mayo'}/>
                <Disertante disertante={'Cra. Lola Berthet'} cargo={'Directora Nacional del Centro Cultural de la Memoria Haroldo Conti'}/>
            </div>
        </div>


        <div className='gridPrograma'>
            <HoraCharla hora={'17:00'}/>
            <div>
                <DetalleCharla tema={'Café - merienda.'} />
            </div>
        </div>


        <div className='gridPrograma'>
            <HoraCharla hora={'17:30'}/>
            <div>
                <DetalleCharla tema={'5to. Panel: "Ley de equidad de medios"'} />
                <Disertante disertante={'Cra. Marianela Mel'} cargo={'SATSAID'}/>
                <Disertante disertante={'Cra. Cecilia Cross'} cargo={'Subsecretaria de Políticas de Inclusión en el Mundo Laboral, Ministerio de Trabajo, Empleo y Seguridad Social'}/>
                <Disertante disertante={'Cra. Carla Gaudensi'} cargo={'FATPREN'}/>
                <Disertante disertante={'Modera: Cra. Mercedes Maffeo'} cargo={'Secretaria de Juventud FATUN'}/>
            </div>
        </div>


        <div className='gridPrograma'>
            <HoraCharla hora={'19:00'}/>
            <div>
                <DetalleCharla tema={'6to. panel: Cierre de la jornada "Políticas de Cuidados y licencias en el mundo laboral"'} />
                <Disertante disertante={'Cra. Claudia Lázzaro'} cargo={'Directora de Políticas de Trabajo y Cuidados para la Igualdad, Ministerio de Mujeres, Políticas de Género y Diversidad Sexual PBA'}/>
                <Disertante disertante={'Cra. Lucía Cirmi'} cargo={'Subsecretaria de Políticas de Igualdad, Ministerio de Mujeres, Género y Diversidad de la Nación'}/>
                <Disertante disertante={'Cra. Vanesa Siley'} cargo={'Secretaria General FE-SITRAJU - Diputada Nacional'}/>
                <Disertante disertante={'Modera: Cra. Marta Juárez'} cargo={'APUNTREF'}/>
            </div>
        </div>



        <div className='mb-4 mt-6'>
            <div className="mt-4 poppins-bold gridPrograma">
                <img src={clock} style={{maxWidth: '1.8rem', margin:'auto'}}/>
                <p className='color is-size-4-desktop is-size-6-mobile'>Jueves 5 de octubre</p>
             </div>
        </div>


        <div className='gridPrograma'>
            <HoraCharla hora={'9:00'}/>
            <div>
                <DetalleCharla tema={'Desayuno.'} />
            </div>
        </div>



        <div className='gridPrograma'>
            <HoraCharla hora={'10:00'}/>
            <div>
                <DetalleCharla tema={'Presentación: Secretarias Generales en FATUN.'} />
            </div>
        </div>


        <div className='gridPrograma'>
            <HoraCharla hora={'11:00'}/>
            <div>
                <DetalleCharla tema={'1er. Panel: "Masculinidades"'} />
                <Disertante disertante={'Cro. Francisco Quiñones'} cargo={'Presidente Asociación Civil Mocha Celis'}/>
            </div>
        </div>


        <div className='gridPrograma'>
            <HoraCharla hora={'12:00'}/>
            <div>
                <DetalleCharla tema={'2do. Panel: "Negociación colectiva con perspectiva de género"'} />
                <Disertante disertante={'Cras. Mariana Campos y Jimena Frankel'} cargo={'Fundación Rosa Luxemburgo'}/>
            </div>
        </div>



        <div className='gridPrograma'>
            <HoraCharla hora={'13:30'}/>
            <div>
                <DetalleCharla tema={'Almuerzo.'} />
            </div>
        </div>


        <div className='gridPrograma'>
            <HoraCharla hora={'15:00'}/>
            <div>
                <DetalleCharla tema={'3er. Panel: "Implementación del cupo laboral travesti trans en las Universidades Nacionales"'} />
                <Disertante disertante={'Cra. Agustina Ponce'} cargo={'Subsecretaria de Políticas de Diversidad, Ministerio de las Mujeres, Géneros y Diversidad'}/>
                <Disertante disertante={'Cra. María Victoria Schadwill'} cargo={'Secretaria General APU'}/>
            </div>
        </div>


        <div className='gridPrograma'>
            <HoraCharla hora={'16:30'}/>
            <div>
                <DetalleCharla tema={'Café - merienda.'} />
            </div>
        </div>


        <div className='gridPrograma'>
            <HoraCharla hora={'17:00'}/>
            <div>
                <DetalleCharla tema={'4to. Panel: "Presupuesto universitario y políticas de género"'} />
                <Disertante disertante={'Cra. Sol Prieto'} cargo={'Directora de Economía, Igualdad y Género, Ministerio de Economía de la Nación'}/>
                <Disertante disertante={'Cra. Sandra Torlucci'} cargo={'Rectora de la Universidad Nacional de las Artes'}/>
            </div>
        </div>


        <div className='gridPrograma'>
            <HoraCharla hora={'19:00'}/>
            <div>
                <DetalleCharla tema={'5to. panel: Cierre del Encuentro'} />
                <Disertante disertante={'Cro. Walter Merkis'} cargo={'Secretario General de la FATUN'}/>
                <Disertante disertante={'Cra. Alicia Luna'} cargo={'Secretaria de Igualdad de Género FATUN'}/>
                <Disertante disertante={'Cra. Ana Laura Ruggiero'} cargo={'Subsecretaria Igualdad de Género FATUN'}/>
                <Disertante disertante={'Cro. Paco Manrique'} cargo={'SMATA'}/>
                <Disertante disertante={'Cra. Karina Moyano'} cargo={'62 Organizaciones'}/>
            </div>
        </div>

    </div>
    )
}

export default ItinerarioContainer
