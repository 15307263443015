// BULMA
import 'bulma/css/bulma.min.css'
// PAGES
import Home from "./pages/Home";
import Programa from "./pages/Programa";
import Info from "./pages/Info";
import HomePageTmp from './pages/HomePageTmp'
import ListaPanelistas from "./pages/ListaPanelistas";
import Panelista from "./pages/Panelista";
import Ubicacion from "./pages/Ubicacion";

// RECT-ROUTER
import {BrowserRouter, Routes,Route} from 'react-router-dom';
import HomePage from './pages/HomePageTmp';
import Wifi from './pages/Wifi';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/wifi' element={<Wifi />} />
        <Route path='/programa' element={<Programa />} />        
        <Route path='/informacion' element={<Info />} />        
        <Route path='/panelista/:nombre' element={<Panelista />} />        
        <Route path='/panelistas' element={<ListaPanelistas />} />        
        <Route path='/ubicacion' element={<Ubicacion />} />       
        <Route path='/homepage' element={<HomePageTmp />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
