import Encabezado from "../componentes/Encabezado";
import Title from "../componentes/Title";
import IconPage from "../componentes/IconPage";
import info from '../img/info.svg'
import imgInfo from '../img/imgInfo.png'
import Footer from "../componentes/Footer";
import IconosVolver from "../componentes/IconosVolver";
import '../App.css'

const Info = () => {


  return (
    <>
      <IconosVolver to={'/'} />
      <Encabezado />
      <IconPage image={info} indice={'Convocatoria'} />
      <div className="container">
        <div className="columns">
          <div className="column has-text-left mt-6 mb-6">
            <p className="mt-5 mb-5">
            Tenemos el agrado de dirigirnos a Ud., a fin de convocar a su Sindicato
            para participar del Primer Encuentro Presencial de Referentes de Género y Diversidad: “El Orgullo
            es una Respuesta Política”.
            </p>
            <p className="mt-2 mb-2">
            El objetivo de este nuevo encuentro es potenciar las políticas sindicales
            aplicadas a fin de fortalecer la equidad de Géneros, entendiendo la misma como la única forma de
            ejercicio real de la Justicia Social.
            </p>
            <p className="mt-2 mb-2">
            El mismo se realizará los días 4 y 5 de octubre de 2023, en la Sede
            Central de FATUN, Av. Belgrano 3768 de la CABA, donde podrán participar hasta dos integrantes
            por Sindicato debiendo realizar la inscripción correspondiente al correo de la Secretaria de Igualdad
            de Género <a href="mailto:secigualdadgenero@fatun.org.ar" target="_blank">secigualdadgenero@fatun.org.ar</a>, hasta el 15 de septiembre de 2023 (indicando Apellido y Nombre, DNI y Correo Electrónico).
            </p>
            <p className="mt-2 mb-2">
            La FATUN cubrirá el alojamiento, de quienes así lo requieran, los días 3, 4 y 5 de octubre, y los almuerzos y cenas del 4 y 5 de octubre.
            </p>
          </div>
        </div>
      </div >


      <Footer texto={'federación argentina del trabajador de las universidades nacionales'} />


    </>
  )
}

export default Info
