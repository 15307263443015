import { Link } from 'react-router-dom';
import '../App.css'
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"

const Disertante = ({disertante, cargo}) => {
    const navigate = useNavigate()

    
    const handleclick = () => { 
        navigate(`/panelista/${disertante}`)
    };
    
    return (
        <>
            <button 
                style={{
                    // textDecoration: 'underline',
                    color: '#0a0a0a',
                    background:'none',
                    border: 'none',
                    textAlign: 'start',
                    padding:'0',
                    fontFamily: 'Poppins, sans-serif',
                    cursor:'pointer'
                }}
                className= 'poppins-bold pt-1 parrafo' 
                onClick={handleclick}>{disertante}</button>
        <p className= 'parrafo'>{cargo}</p>
        </>
    )
 }

 export default Disertante;