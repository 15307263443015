import logoFatun from '../img/logo-encuentro.svg'
import fondo from '../img/fondo-50.svg'
import Title from './Title'
import '../App.css'
import { Link } from 'react-router-dom'
import IconosVolver from './IconosVolver'
import { Layout } from '../layout'


const Encabezado = ({backgroundImage}) => {
    return (
        <>
        <div className="columns is-mobile has-text-centered mt-6">
            <div className="column sectionConstruction">
            <header className='header p-6 '>
            <img src={logoFatun}  alt='Logo Encuentro de Género y Diversidad'/>
            </header>
            <div className='is-flex is-relative'
                    style={{
                        backgroundImage: backgroundImage,
                        backgroundPosition: `center`,
                        // height: '5vh',
                        }}
                    >
                    <a href='https://www.fatun.org.ar/'>
                    <img style= {{cursor: 'pointer' }} className= 'is-block m-auto img-responsive' alt="" /></a>
                </div>

            </div>
          </div>
        </>
    )
}

export default Encabezado
