import face from '../img/facebook-2 1.svg'
import inst from '../img/instagram 1.svg'
import { Link } from 'react-router-dom'

import iconDir from '../img/chevron-left 1.svg'
const IconoDetalle =()=>{
    const style = {
        lineal: {
            borderTop: '3px solid #BDBDBD',
            //position: 'fixed',
            bottom: '0',
            left: '0',
            right: '0',
            width: '90%',
            margin: 'auto',
            padding: '1rem 0',
            textAlign: 'center',
            background: '#fff',
        },
    }
    return(
        <>
        <div style={style.lineal}>

            <ul className="title has-text-centered ">
                <a href='http://www.fatun.org.ar/noticias' target="_blank"><li className='poppins-italic poppins-bold is-size-5 has-text-black'>Ver Noticias <img src={iconDir} /> </li></a> <br></br>
                <Link to='/wifi'><li className='poppins-italic poppins-bold is-size-5 has-text-black'>Conexión Wifi <img src={iconDir} /> </li><br></br></Link>
                <a href='http://www.fatun.org.ar' target="_blank"><li className='poppins-italic poppins-bold is-size-5 has-text-black'>Ir al sitio <img src={iconDir} /> </li></a><br></br>
            </ul>
        </div>
        <div className='gridRedes'>
                <div className='m-1' style={{cursor:'pointer'}}>
                <a href='https://www.facebook.com/fatun.oficial' target="_blank"> <img src={face} alt="" className='p-1 icon-responsive'/> </a>
                </div>

                <div className='m-1' style={{cursor:'pointer'}}>
                <a href="https://www.instagram.com/fatun.oficial/"  target="_blank" >
                    <img src={inst} alt="" className='p-1 icon-responsive'/>
                 </a>
                </div>
         </div>
        </>
    )
}
export default IconoDetalle
